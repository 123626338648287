.thank-you-page-button {
    background-color: #8d58f7;
    border-color: #8d58f7;
    border-radius: 14px;
    height: 48px;
    width: 100%
}

.giveaway-link {
    color: #8d58f7;
    text-decoration: none;
}