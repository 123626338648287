
.menuItemDetailRoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.menuItemDetailsImg {
    width: 100vw;
}

.menuItemDetailsTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.menuItemDetailsDescription {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
}

.singleMenuItemPrice {
    width: 100vw;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
    color: #7546E0;
}

.menuItemPrice {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    display: flex;
    justify-content: right;
    align-items: center;
    color: #7546E0;
}

.orderButtonContainer {
    display: flex;
    justify-content: center;
}

.orderButton {
    width: 80%;
    border-radius: 15px;
    background-color: #7546E0;
}