.thankYouContainer {
    display: flex;
    align-items: center;
}
.thankYouTitle {
    font-size: large;
    font-weight: bold;
}

.thankYouText {
    font-weight: bold;
}

.thankYouDescription {
    text-align: center;
}

.thankYouRating {
    display: flex;
    justify-content: center;
}

.submit-button {
    background-color: #8d58f7;
    border-color: #8d58f7;
    border-radius: 14px;
    height: 48px;

    &:disabled {
        background-color: lightslategrey;
        opacity: 1;
        border-color: lightslategrey;
    }
}

.button-wrapper {
    bottom: 2rem;
}

.value-input {
    font-size: 2rem;
    text-align: center;
    width: 70%;
    height: 100px;
    border-radius: 14px;
    border: transparent;
    box-shadow: 0 0 24px rgba(0, 0, 0, .1)
}

.value-wrapper {
    display: flex;
    justify-content: center;
}

.employeeCardFlex {
    flex-grow: 1;
}