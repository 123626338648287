.marginLeft {
    margin-left: 5%;
}

.paragraph {
    margin-top: 35px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
}

.link {
    text-decoration: none;
    color: inherit;
}