.mainWrapper {
    padding-top: 24px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.logoWrapper {
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.logo {
    width: 142px;
    padding: 5%;
    grid-column-start: 2;
}