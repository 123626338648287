.submit-button-payer {
    background-color: #8d58f7;
    border-color: #8d58f7;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 14px;
    height: 48px;
    &:disabled {
        background-color: lightslategrey;
        opacity: 1;
        border-color: lightslategrey;
    }
}

.link {
    text-decoration: none;
    color: inherit;
}

.terms-privacy-link {
    color: #8d58f7;
    text-decoration: none;
}

.disabled-link {
    pointer-events: none;
}

.payer-input {
    height: 24px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0;
    background-color: transparent;
    font-size: 12px;
    line-height: 14px;

    &:focus {
        background-color: transparent;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.3);
    }
}

.payer-input-icon {
    background-color: transparent;
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.input-wrapper {
    display: flex;
    justify-content: space-between;
}

.checkbox {
    font-size: 12px;

    :checked {
        background-color: #8d58f7;
    }
}
