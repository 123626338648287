.serviceOrderRoot {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.backServiceOrderButton {
    width: 90%;
    height: 48px;
    border-radius: 14px;
    background-color: #7546E0;
}

.backButtonRoot {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preparationStatusRoot {
    display: flex;
}