.businessCard {
    border-radius: 24px;
    border: transparent;
}

.categoryName {
    margin-top: 24px;
    font-size: 14px;
    margin-bottom: 12px;
}

.scrollMenuWrapper {
    margin-bottom: -0.5rem;
    margin-top: 2rem
}