.flexWrapper {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 35px;
}

.backButton {
    border-radius: 14px;
    height: 45px;
    width: 45px;
    align-items: center;
    font-size: 30px;
}