.menuItemCard {
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    border: transparent;
    height: 105px;
}

.img {
    float: left;
    width:  105px;
    height: 105px;
    object-fit: cover;
    border-radius: 15px;
}

.menuItemColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.menuItemName {
    font-weight: 700;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}