.tabSwitchCard {
    border-radius: 14px;
    border: transparent;
}

.tabColumn {
    text-align: center;
}

.tabSpanInactive {
    opacity: 0.24;
}

.tabIcon {
    margin-right: 5px;
}

.tabText {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    @media all and (max-width: 350px) {
        font-size: 10px;
    }
}

.tabTextActive {
    color: #7546E0;
}

.tabTextInactive {
    color: #000000;
}

.tabButton {
    background-color: transparent;
    border: transparent;
}

.tabHr {
    position: relative;
    width: 80px;
    height: 2px !important;
    color: #7546E0;
    opacity: 1;
    border-radius: 1px;
    margin: 14px auto -17px;
}

.tabHrInactive {
    color: transparent;
}
