.employeeCard {
    border-radius: 24px;
    border: transparent;
}

.img {
    float: left;
    width:  75px;
    height: 75px;
    object-fit: cover;
    border-radius: 24px;
}

.imgColumn {
    flex-grow: 1;
}

.nameColumn {
    flex-grow: 4;
}

.employeeCardDisplayName {
    font-weight: bold;
}