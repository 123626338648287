.cartRoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cartPayButtonRoot {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cartPayButton {
    width: 90%;
    height: 48px;
    border-radius: 14px;
    background-color: #7546E0;
    &:disabled {
         background-color: lightslategrey;
         opacity: 1;
         border-color: lightslategrey;
    }
}

.alignRight {
    text-align: right;
}

.boldText {
    font-weight: bold;
}

.tableNumberInput {
    border-radius: 15px;
    width: 18%;
    text-align: center;
}

.tableNumberRoot {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tableNumberLabel {
    font-weight: bold;
}