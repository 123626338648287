.badge {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 12px;
    margin-bottom: 55px;
    z-index: 1000;
    background-color: #7546E0;
}

.cartImage {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    padding: 12px;
    width: 60px;
    border-radius: 30px;
    background-color: #7546E0;
}