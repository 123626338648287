.menuItemCounterPrice {
    font-weight: 700;
    font-size: 12px;
    color: #7546E0;
    text-align: right;
}

.menuItemRow {
    margin-bottom: 0.25rem;
}

.trashImage {
    margin-left: 5px;
    height: 35px;
    padding: 5px;
    border-radius: 15px;
}

.trashContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}