.counterRoot {
    width: 100%;
    border-radius: 15px;
}

.count {
    background-color: white;
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.countButton {
    background-color: white;
    color: black;
    border-color: white;
    border-radius: 15px;
}