.businessCard {
    background: inherit;
}

.businessCardBody {
    padding-top: 0;
    text-align: center;
}

.businessCardPhoto {
    margin-bottom: 0.5rem;
    width: 5rem;
}

.businessCardTitle {
    font-size: 1rem;
    font-weight: 700;
    line-height: 0.5rem;
}

.businessCardText {
    font-size: 0.75rem;
    opacity: 0.7;
}