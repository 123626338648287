.payment-group-img {
    width: 100px;
    padding-left: 10px;
}

.payment-group-row {
    height: 64px;
}

.group-name-column {
    font-weight: 600;
}

.payment-group-card {
    border-radius: 16px;
    border: transparent;
    height: 64px;
}

.dot-active {
    height: 25px;
    width: 25px;
    background-color: #8d58f7;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
}

.dot-inactive {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border-color: #8d58f7;
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    align-self: center;
}

.dot-column {
    height: 25px
}