.categoryChipCard {
    height: 38px;
    line-height: 38px;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border-radius: 14px;
    margin-right: 16px;
}

.chipSelected {
    background-color: #8d58f7;
    border-color: #8d58f7;
    color: white;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}
