.menuItemPrice {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 16px;
    text-align: end;
}

.menuItemDescription {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}